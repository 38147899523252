export const environment = {
  name: 'qa',
  production: false,
  payment: {
    stripe: {
      publicKey:
        'pk_test_51L2VozF5dZc8xOzMtrtK4EkHMcxPZXtl93xf03eeSUnPJkuMvzEobvGp0dV0xOgJ4EsW91sIuwC4D9hdyRGWUtl700YlVDp9by',
    },
  },
  url: {
    graphQL: 'https://onep-gateway.qa.onep.wedoogift.com/graphql',
    glady: {
      home: 'https://home-page.qa.tech.glady.com',
      manager: 'https://manager.qa.glady.com',
    },
    payment: {
      success: 'https://pay.qa.glady.com/payment-success',
    },
  },
  email: {
    sav_order: 'sav.commande@glady.com',
    manager: 'manager@glady.com',
  },
};
